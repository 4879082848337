<template>
    <b-overlay :show="loading || (!loading && !fetchedData)" spinner spinner-variant="primary">
        <slot :data="fetchedData"/>
        <template #overlay v-if="noDataMessage && !loading && !fetchedData">
            <div class="text-center h-5 text-muted">
                {{ noDataMessage }}
            </div>
        </template>
    </b-overlay>
</template>

<script>
    import axios from '@/setup/axios-setup.js';
    import Toast from '@/toast';
    const toast = new Toast();

    export default {
        props: {
            endpoint: {
                type: String,
                required: true,
            },
            processResponse: {
                type: Function,
                required: false
            },
            okCallback: {
                type: Function,
                required: false
            },
            errorCallback: {
                type: Function,
                required: false
            },
            errorMessage: {
                type: String,
                required: false,
            },
            noDataMessage: {
                type: String,
                required: false,
            },

        },
        emits: ['update:fetchedData', 'update:loading'],
        data() {
            return {
                loading: false,
                fetchedData: null,
            }
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.loading = true;
                axios.get(this.endpoint).then(response => {
                    let newFetchedData = null;
                    if (this.processResponse) {
                        newFetchedData = this.processResponse(response);
                    } else {
                        newFetchedData = response.data
                    }
                    this.fetchedData = newFetchedData;

                    if (this.okCallback) {
                        this.okCallback(response)
                    }
                }).catch(error => {
                    if (this.errorCallback) {
                        this.errorCallback(error);
                    } else {
                        toast.error(this.errorMessage || this.defaultErrorMessage, "Error")
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        computed: {
            defaultErrorMessage() {
                return this.$t('common.endpointDefaultError');
            }
        },
        watch: {
            loading(newLoading) {
                this.$emit('update:loading', newLoading);
            },
            fetchedData(newFetchedData) {
                this.$emit('update:fetchedData', newFetchedData);
            }
        }
    }
</script>
