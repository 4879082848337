<template>
    <div class="mb-5" v-cloak>
        <div class="sticky-element sticky-element--no-desktop">
            <h1 class="h5">{{ title }}</h1>
            <div class="d-flex justify-content-between align-items-center">
                <app-tabs v-cloak @update="switchApps"></app-tabs>
                <div>
                    <post-items-periods
                        ref="periodSelector"
                        :excludeAllPeriod="true"
                        :hideLabel="true"
                        :upcoming="queryType === 'upcoming'"
                        @post-period-update="selectPeriod">
                    </post-items-periods>
                </div>
            </div>
        </div>

        <div class="mt-3">
            <post-list
                v-if="selectedApp && selectedApp !== 'emarketing'"
                :acc-id="profile.applications[selectedApp].data.acc_id"
                :date-from="dateStart"
                :date-until="dateEnd"
                :query-type="queryType"
                :app="selectedApp">
            </post-list>

            <emarketing-post-list
                v-if="selectedApp && selectedApp === 'emarketing'"
                :emarketing-id="profile.applications[selectedApp].data.acc_id"
                :date-from="dateStart"
                :date-until="dateEnd"
                :query-type="queryType">
            </emarketing-post-list>
        </div>

    </div>
</template>

<script>
    import PostItemsPeriods from '@/components/post/PostItemsPeriods.vue';
    import PostList from '@/components/post/PostList.vue';
    import EmarketingPostList from '@/components/emarketing/EmarketingPostList.vue';
    import AppTabs from '@/components/dashboard/AppTabs.vue';

    export default {
        name: 'AppPosts',
        components: {
            PostItemsPeriods,
            PostList,
            EmarketingPostList,
            AppTabs,
        },
        props: {
            queryType: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
        },
        data: function () {
            return {
                profile: '',
                selectedApp: '',
                dateStart: '',
                dateEnd: '',
            };
        },
        methods: {
            switchApps(app) {
                this.selectedApp = app;
                if('periodSelector' in this.$refs && this.$refs.periodSelector){
                    this.$refs.periodSelector.setPeriodOptions();
                    this.$refs.periodSelector.selectPeriod(0);
                }
            },
            selectPeriod(values) {
                this.dateStart = values[values.length - 2]; // item before last
                this.dateEnd = values[values.length - 1]; // last item
            }
        },
        created() {
            this.profile = window.profileData;
        },
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/_functions.scss";
    @import "~bootstrap/scss/_variables.scss";

    .sticky-element {
        background: #fff;
        padding-top: 10px;

        &:after {
            content: "";
            border-bottom: 1px solid $nav-tabs-border-color;
            width: 100%;
            display: block;
            margin-top: -1px;
        }
    }
</style>
