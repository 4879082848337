<template>
    <social-link :app="app"></social-link>

    <h1 class="h5 mb-4">{{ $t("common.postingSchedule") }}</h1>


    <section class="row">
        <div class="col-lg-7">
            <connection-alert :app="app"></connection-alert>

            <scheduled-tabs
                ref="tabs"
                :is-paused="profile.applications[app].paused"
                @select="selectTab($event)">
            </scheduled-tabs>

            <div class="d-flex justify-content-end my-3">
                <div v-if="currentTab === tabs.UPCOMING" class="mr-3">
                    <post-items-periods-created
                        v-if="profile.advance_post_filter"
                        ref="periodSelectorCreated"
                        @post-period-update="selectPeriodCreated">
                    </post-items-periods-created>
                </div>
                <div v-if="currentTab !== tabs.DELETED && !createdStart">
                    <post-items-periods
                        ref="periodSelector"
                        :upcoming="currentTab === tabs.UPCOMING"
                        @post-period-update="selectPeriod">
                    </post-items-periods>
                </div>
            </div>

            <post-list
                :acc-id="profile.applications[app].data.acc_id"
                :date-from="dateStart"
                :date-until="dateEnd"
                :created-from="createdStart"
                :created-until="createdEnd"
                :query-type="currentTab"
                :app="app"
                @refresh="refreshList">
            </post-list>

        </div>

        <div class="col-lg-5 d-none d-lg-block">
        </div>
    </section>

</template>

<script>
    import ScheduledTabs from '@/components/common/ScheduledTabs.vue';
    import SocialLink from '@/components/common/SocialLink.vue';
    import PostItemsPeriods from '@/components/post/PostItemsPeriods.vue';
    import PostItemsPeriodsCreated from '@/components/post/PostItemsPeriodsCreated.vue';
    import PostList from '@/components/post/PostList.vue';
    import ConnectionAlert from '@/components/common/ConnectionAlert.vue';
    import { tabs } from '@/components/common/scheduled-tabs-config.js';
    import { appStringProp } from '@/utils/props.js';

    export default {
        name: 'SocialPosts',
        components: {
            ConnectionAlert,
            PostItemsPeriods,
            PostItemsPeriodsCreated,
            PostList,
            ScheduledTabs,
            SocialLink,
        },
        props: {
            app: appStringProp,
        },
        data() {
            return {
                profile: window.profileData,
                currentTab: tabs.UPCOMING,
                dateEnd: null,
                dateStart: null,
                createdEnd: null,
                createdStart: null,
                tabs,
            };
        },
        methods: {
            selectTab(tabName){
                this.currentTab = tabName;
                this.createdStart = null;
                this.createdEnd = null;
                this.dateStart = null;
                this.dateEnd = null;
                if('periodSelector' in this.$refs && this.$refs.periodSelector){
                    this.$refs.periodSelector.selectAll();
                }
                if('periodSelectorCreated' in this.$refs && this.$refs.periodSelectorCreated){
                    this.$refs.periodSelectorCreated.selectNone();
                }
            },
            selectPeriod(values){
                this.dateStart = values[values.length - 2]; // item before last
                this.dateEnd = values[values.length - 1]; // last item
                this.createdStar = null;
                this.createdEnd = null;
            },
            selectPeriodCreated(value){
                this.createdStart = value['fromDate'];
                this.createdEnd = value['toDate'];
                this.dateStart = null;
                this.dateEnd = null;
            },
        },
    }
</script>
