<template>
    <div class="accordion-item">
        <a
            href="javascript:void(0);"
            @click="handleClick"
            class="accordion-toggle">
            <span class="h6 mb-0">
                <slot name="toggle"></slot>
            </span>
            <i class="fa" :class="visible ? `fa-chevron-up` : `fa-chevron-down`" aria-hidden="true"></i>
        </a>
        <b-collapse v-model="visible" class="accordion-content">
            <slot name="content"></slot>
        </b-collapse>
    </div>
</template>

<script>
    export default {
        name: 'AccordionItem',
        emits: ['toggle'],
        data() {
            return {
                visible: false
            }
        },
        methods: {
            handleClick() {
                this.visible = !this.visible;
                this.$emit('toggle', this.visible);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .accordion-item {
        border-top: 1px solid #ccc;
    }

    .accordion-toggle,
    .accordion-content {
        padding: 25px 20px;
    }

    .accordion-content {
        padding-top: 0;
    }

    .accordion-toggle {
        align-items: center;
        color: #4a4a68;
        display: flex;
        justify-content: space-between;
    }
</style>
