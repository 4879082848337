<template>
    <div class="row">
        <template v-if="anyAppConnected">
            <div class="col-md-6">
                <app-posts
                    id="bw-upcoming-col"
                    :title="$t('dashboard.upcoming.title')"
                    query-type="upcoming">
                </app-posts>
            </div>
            <div class="col-md-6">
                <app-posts
                    id="bw-top-col"
                    :title="$t('dashboard.top.title')"
                    query-type="top">
                </app-posts>
            </div>
        </template>

        <div v-else class="col-md-12">
            <div class="no-connections">
                <div class="no-connections__icon">
                    <i class="fa fa-3x fa-bullhorn" aria-hidden="true"></i>
                </div>
                <div class="text-center w-75 m-auto">
                    <h1 class="h5 mb-3">{{ $t("dashboard.noConnection.title") }}</h1>
                    <p>{{ $t("dashboard.noConnection.desc") }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppPosts from '@/components/dashboard/AppPosts.vue';
    import { connectionConfigValues } from '@/components/apps-connection-config.js';

    export default {
        name: 'AdvisorDashboard',
        components: {
            AppPosts
        },
        data: function () {
            return {
                profile: window.profileData,
            };
        },
        computed: {
            anyAppConnected() {
                // TODO: use store to get enabled apps. Currently store is too slow to be used here.
                return Object.keys(connectionConfigValues).filter(app_slug => window.profileData.applications[app_slug].is_enabled).length > 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/_variables.scss";
    .no-connections {
        display: flex;
        flex-direction: column;

        &__icon {
            align-items: center;
            align-self: center;
            background: $cloud;
            border: 1px dashed $greyish;
            border-radius: 50%;
            color: $greyish-500;
            display: flex;
            height: 110px;
            justify-content: center;
            margin-bottom: 2rem;
            width: 110px;
        }
    }
</style>
