<template>
    <b-nav tabs v-cloak>
        <b-nav-item
            v-for="app in apps"
            @click="switchApps(app)"
            :active="app === selectedApp"
            :key="app">
            <i
                :class="`${connectionConfig[app].icon} text-${app}`"
                class="fa fa-fw fa-2x"
                aria-hidden="true">
            </i>
        </b-nav-item>
    </b-nav>
</template>

<script>
    import { connectionConfigValues } from '@/components/apps-connection-config.js';

    export default {
        name: 'AppTabs',
        emits: ['update'],
        data() {
            return {
                selectedApp: '',
                connectionConfig: connectionConfigValues,
            };
        },
        computed: {
            apps() {
                // TODO: use store to get enabled apps
                let apps = Object.keys(connectionConfigValues).filter(app_slug => window.profileData.applications[app_slug].is_enabled);
                return apps.sort((a, b) => connectionConfigValues[a].order - connectionConfigValues[b].order);
            }
        },
        methods: {
            switchApps(app) {
                this.selectedApp = app;
                this.$emit('update', app);
            },
        },
        mounted() {
            this.switchApps(this.apps[0]);
        }
    }
</script>
