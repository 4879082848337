<template>
    <div class="card mb-3" v-if="this.profile.plan">
        <div class="card-body">
            <h5>PRO Membership</h5>

            <b-embed
                aspect="16by9"
                allowfullscreen
                class="my-2"
                type="iframe"
                :src="(this.profile.plan === 'pro') ? billing_setup_video_url : billing_mkt_video_url">
            </b-embed>

            <p class="text-center mt-2 mb-0">
                <a class="reset-link" :href="routes.plan(profile.slug)">
                    <small>Learn more about PRO Membership</small>
                </a>
            </p>
        </div>
    </div>
</template>

<script>
    import { routes } from '@/components/common/views-config.js';

    export default {
        name: 'PremiumCard',
        data: function () {
            return {
                billing_mkt_video_url: window.billing_mkt_video_url,
                billing_setup_video_url: window.billing_setup_video_url,
                profile: '',
                routes
            };
        },
        created() {
            this.profile = window.profileData;
        }
    }
</script>
