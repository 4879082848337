<template>
    <div id="bw-connections" class="card mb-3">
        <div class="card-body">
            <h5>{{ $t("connection.title") }}</h5>
            <p>{{ $t("connection.description") }}</p>

            <ul v-for="(appData, app) in connectionConfig" :key="app" class="list-unstyled mb-0">
                <apps-connection-item
                    v-if="profile.applications[app].visible && app !== 'emarketing'"
                    :profile="profile"
                    :app="app"
                    :endpoint="appData['endpoint']">
                </apps-connection-item>
                <apps-connection-item-em
                    v-if="profile.applications[app].visible && app === 'emarketing'"
                    :profile="profile"
                    :app="app"
                    :endpoint="appData['endpoint']">
                </apps-connection-item-em>
            </ul>
        </div>
    </div>
</template>

<script>
    import AppsConnectionItem from './AppsConnectionItem.vue';
    import AppsConnectionItemEm from './AppsConnectionItemEm.vue';
    import {connectionConfigValues as connectionConfig} from './apps-connection-config.js';

    export default {
        name: 'AppsConnection',
        components: {
            AppsConnectionItem,
            AppsConnectionItemEm,
        },
        data: function () {
            return {
                profile: '',
                connectionConfig
            };
        },
        created() {
            this.profile = window.profileData;
        },
    }
</script>
