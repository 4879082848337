import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            applications: {
                facebook: {
                    installed: null,
                    connected: null
                },
                instagram: {
                    installed: null,
                    connected: null
                },
                linkedin: {
                    installed: null,
                    connected: null
                },
                emarketing: {
                    installed: null,
                    connected: null
                }
            },
            upcomingColState: null
        }
    },
    mutations: {
        setApplicationState(state, {appName, newState}) {
            state.applications[appName] = newState;
        },
        updateUpcomingColState(state, newData) {
            state.upcomingColState = newData;
        }
    },
    actions: {
        applicationState({ commit }, {appName, newState}) {
            commit('setApplicationState', {appName, newState});
        },
        updateUpcomingCol({ commit }) {
            const randomNum = Math.floor(Math.random() * 1000);
            commit('updateUpcomingColState', randomNum);
        }
    },
    getters: {
        getApplicationState: (state) => (appName) => state.applications[appName],
        anyApplicationConnected: (state) => {
            return Object.values(state.applications).some(app => app.connected === true);
        }
    },
});

export default store;
