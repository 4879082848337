<template>
    <div>
        <div class="dropdown" v-if="canEdit || canRestore">
            <a
                href="javascript:void(0);"
                class="text-reset btn btn-circle-light btn-circle"
                data-toggle="dropdown"
                aria-expanded="false">
                <i class="fa fa-lg fa-ellipsis-h" aria-hidden="true"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <a
                    v-if="edit && !post.deleted"
                    @click="showModal(`editPost${post.id}`)"
                    class="dropdown-item"
                    href="javascript:void(0);">
                    {{ $t("common.edit") }}
                </a>
                <a
                    v-if="reschedule && !post.deleted"
                    @click="showModal(`reschedulePost${post.id}`)"
                    class="dropdown-item"
                    href="javascript:void(0);">
                    {{ $t("common.reschedule") }}
                </a>
                <a
                    v-if="postnow && !post.deleted"
                    @click="showModal(`postNow${post.id}`)"
                    class="dropdown-item"
                    href="javascript:void(0);">
                    {{ $t("common.postNow") }}
                </a>
                <a
                    v-if="postdelete && !post.deleted"
                    @click="showModal(`postDelete${post.id}`)"
                    class="dropdown-item"
                    href="javascript:void(0);">
                    {{ $t("common.delete") }}
                </a>
                <a
                    v-if="restore && post.deleted"
                    @click="showModal(`postRestore${post.id}`)"
                    class="dropdown-item"
                    href="javascript:void(0);">
                    {{ $t("common.restore") }}
                </a>
            </div>
        </div>

        <post-actions-edit
            :okCallback="updatePost"
            :post="post"
            :profileCount="profileCount">
        </post-actions-edit>

        <post-actions-reschedule
            :okCallback="updateParent"
            :post="post"
            :profileCount="profileCount">
        </post-actions-reschedule>

        <post-actions-post-now
            :okCallback="updateParent"
            :post="post"
            :profileCount="profileCount">
        </post-actions-post-now>

        <post-actions-delete
            :okCallback="updateParent"
            :post="post"
            :profileCount="profileCount">
        </post-actions-delete>

        <post-actions-restore
            :okCallback="updateParent"
            :post="post"
            :profileCount="profileCount">
        </post-actions-restore>
    </div>
</template>

<script>
    import PostActionsDelete from './PostActionsDelete.vue';
    import PostActionsEdit from './PostActionsEdit.vue';
    import PostActionsPostNow from './PostActionsPostNow.vue';
    import PostActionsReschedule from './PostActionsReschedule.vue';
    import PostActionsRestore from './PostActionsRestore.vue';
    import { setDate } from '@/utils/datetime';

    export default {
        name: 'PostActions',
        emits: ['update-item', 'add-item'],
        components: {
            PostActionsDelete,
            PostActionsEdit,
            PostActionsPostNow,
            PostActionsReschedule,
            PostActionsRestore
        },
        props: {
            edit: {
                type: Boolean,
                default: true
            },
            post: {
                type: Object,
                required: true
            },
            postdelete: {
                type: Boolean,
                default: true
            },
            postnow: {
                type: Boolean,
                default: true
            },
            reschedule: {
                type: Boolean,
                default: true
            },
            restore: {
                type: Boolean,
                default: true
            },
            refreshParent: {
                type: String,
                required: false
            }
        },
        data: function () {
            return {
                item: this.$props.post,
                profileCount: ''
            };
        },
        computed: {
            isUpcoming(){
                return this.item.fire_at.isAfter(setDate.now());
            },
            canRestore(){
                return this.item.deleted && this.isUpcoming;
            },
            canEdit(){
                return this.item.status === 0 && this.isUpcoming;
            }
        },
        created() {
            this.profileCount = window.profileData.profiles_count;
        },
        methods: {
            updatePost(response){
                // update element without reload
                this.$emit('update-item', response.data);
            },
            updateParent() {
                // Emit an add item element to refresh parent
                this.$emit('add-item');
                this.$props.refreshParent && window.store.dispatch(this.$props.refreshParent);
            },
            showModal(target) {
                this.$bvModal.show(target);
            }
        }
    }
</script>
